import React, { useState, useEffect, useContext } from 'react'
import DashboardLayout from '../index'
import { IoIosSend } from 'react-icons/io'
import useQueryString, { getQueryStringValue } from '../../../../components/queryString'
import { IoChevronBack } from 'react-icons/io5'
import axios from '../../../../utilis/axios';
import { Store } from '../../../../store';
import moment from 'moment'
import { saveMessages, saveStudents } from '../../../../store/actions/instructor';
import Skeleton from 'react-loading-skeleton'
import { useChannel, useEvent } from '@harelpls/use-pusher'
import cogoToast from 'cogo-toast'
import { Card, Image } from 'react-bootstrap'
import FileHelper from '../../../../components/helpers/file.helper'
import { containsContinuousNumbers } from '../../../../utilis/helpers'

const Messages = (props) => {

    const { state, dispatch } = useContext(Store)
    const [user, setUser] = useQueryString("user");
    const [students, setStudents] = useState()
    const [messages, setMessages] = useState({});
    const [searchInput, setSearchInput] = useState();
    const [draft, setDraft] = useState(props.location ? (props.location.state ? (props.location.state.draft ? props.location.state.draft : '') : '') : '');
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false)
    const channel = useChannel(state.user.data._id);
    const [activeStudents, setActiveStudents] = useState([])

    useEvent(channel, "private-message", async (data) => {
        editMessage(data.user._id, data.message, moment(), 'student', getQueryStringValue('user'), data.messageId)
    });



    useEffect(() => {
        if (!state.instructor.chat_students || state.instructor.chat_students.length === 0) {
            fetchActiveStudents();
            fetchStudents();
        }
        else {
            setStudents(state.instructor.chat_students)
            setMessages(state.instructor.chat_messages)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (state.instructor.chat_students.length > 0) {
            setStudents(state.instructor.chat_students)
        }
    }, [state.instructor.chat_students])

    useEffect(() => {
        if (Object.keys(state.instructor.chat_messages).length > 0) {
            setMessages(state.instructor.chat_messages)
        }
        // eslint-disable-next-line
    }, [state.instructor.chat_messages])



    const fetchStudents = async () => {
        try {
            const res = await axios.get('/api/instructor/enrollments');
            const arr = res.data.enrollments.map(item => ({
                _id: item.student._id,
                name: item.student.firstName + ' ' + item.student.lastName,
                avatar: item.student.avatar,
                unreadMsg: 0,
            }))
            const unique = getDistinct(arr)
            fetchMessages(unique);
        } catch (err) {
            console.log(err)
        }
    }

    const fetchActiveStudents = async () => {
        try {
            await axios.get('/api/instructor/student/active').then(res => {
                if (res.status == 200) {
                    setActiveStudents(res.data.active_students)
                }
            })

        } catch (err) {
            console.log(err)
        }
    }

    console.log("Active Students : ", activeStudents);

    const fetchMessages = async (studentsTemp) => {
        try {

            const res = await axios.get('/api/instructor/messages');
            const newStudents = getDistinct([...studentsTemp, ...res.data.map(item => ({ _id: item.student._id, avatar: item.student.avatar, name: item.student.firstName + ' ' + item.student.lastName, unreadMsg: 0 }))]);

            const temp = [];
            const temp2 = newStudents.map(item => {
                const allMessages = res.data.filter(item2 => item2.student._id === item._id);
                temp[item._id] = allMessages.map(item3 => ({
                    from: item3.from,
                    message: item3.message,
                    time: item3.sent_date,
                    isRead: item3.isRead,
                    _id: item3._id
                })).sort((a, b) => (new Date(b.time) - new Date(a.time)))
                if (temp[item._id].length === 0) {
                    return item
                } else {
                    console.log("temp[item._id][0].time : ", moment(temp[item._id][0].time).format('LT'));
                    return {
                        ...item,
                        lastMsg: temp[item._id][0].message,
                        lastMsgTime: temp[item._id][0].time,
                        unreadMsg: temp[item._id].reduce((a, b) => {
                            if (!b.isRead && b.from === 'student') return a + 1
                            else return a
                        }, 0),
                    }
                }
            })
            const temp3 = temp2
                .sort((a, b) => {
                    if (b.lastMsgTime) {
                        return new Date(b.lastMsgTime) - new Date(a.lastMsgTime)
                    } else return -1
                })
            setStudents(temp3)
            setMessages(temp)
            saveStudents(temp3, dispatch)
            saveMessages(temp, dispatch)

        } catch (err) {
            console.log(err);
        }
    }


    console.log("Students : ", students);
    const sendMessage = async (e) => {
        e.preventDefault();
        try {
            const new_message_id = new Date() + 1
            setLoading(true);
            setLoading1(new_message_id)

            if (!draft || draft.trim() === '') return;


            // if (cleanMessage(draft)) {
            //     cogoToast.error("Please refrain from sharing your contact details with anyone through the messages. Anyone found attempting the same would not be able to use Dhunguru’s platform in future. ");
            //     setLoading(false);
            //     return;
            // }

            editMessage(user, draft, moment(), 'instructor', getQueryStringValue('user'), new_message_id)
            const data = {
                message: draft,
                student: user,
            }

            setDraft('');
            await axios.post('/api/instructor/messages/send', data);
            setLoading(false)
            setLoading1('')

        } catch (err) {
            cogoToast.error("Error occured, we were not able to send your last message. Please refresh and try again");
            setLoading(false)
            setLoading1('')
            console.log(err);
        }
    }

    useEffect(() => {
        if (user && messages[user] && messages[user].filter(item => (!item.isRead && item.from === 'student')).length > 0) {
            readMessages()
        }
        // eslint-disable-next-line
    }, [user])

    const readMessages = async () => {
        try {
            await axios.post(`/api/instructor/messages/read/${user}`)
            const newMessages = state.instructor.chat_messages;
            const newStudents = state.instructor.chat_students
            newMessages[user] = newMessages[user].map(item => {
                if (!item.isRead && item.from === 'student') {
                    return { ...item, isRead: true }
                } else return item
            })
            const index = newStudents.findIndex(item => item._id === user);
            newStudents[index].unreadMsg = 0;
            saveStudents(newStudents, dispatch)
            saveMessages(newMessages, dispatch);

        } catch (err) {
            console.log(err)
        }
    }



    const editMessage = (userId, message, time, from, user, message_id) => {
        try {
            const allMessages = Object.values(state.instructor.chat_messages).flat()
            if (allMessages.filter(item => item._id === message_id).length > 0) {
                // console.log("Duplicate")
                return;
            }
            const newMessages = { ...state.instructor.chat_messages };
            let newStudents = [...state.instructor.chat_students]
            if (!newMessages[userId]) {
                newMessages[userId] = []
            };
            newMessages[userId] = [{
                from: from,
                message: message,
                time: time,
                isRead: user === userId ? true : false,
                _id: message_id
            }, ...newMessages[userId]]

            const index = newStudents.findIndex(item => item._id === userId);
            if (index < 0) {
                return;
            }
            newStudents[index].lastMsgTime = new Date();
            newStudents[index].lastMsg = message.length > 20 ? message.slice(0, 20) + '...' : message;
            newStudents[index].unreadMsg = newMessages[userId].reduce((a, b) => {
                if (!b.isRead && b.from === 'student') return a + 1
                else return a
            }, 0)

            newStudents = newStudents.sort((a, b) => {
                if (b.lastMsgTime) {
                    return new Date(b.lastMsgTime) - new Date(a.lastMsgTime)
                } else return -1
            })

            saveStudents(newStudents, dispatch)
            saveMessages(newMessages, dispatch);
        }
        catch (err) {
            console.log(err)
        }

    }


    return (
        <DashboardLayout showTitle={false} {...props}>
            <div className='row pt-5' style={{ maxHeight: "87vh" }}>
                <div className={`col-lg-5 col-xl-4 d-lg-block ${user ? "d-none" : ""}`}>

                    <div class='row align-items-center mb-4'>
                        <div className='col h1 mb-0'>
                            Chats
                        </div>
                    </div>

                    <Search searchInput={searchInput} setSearchInput={setSearchInput} />
                    {students ? <MessageNavigation
                        students={students}
                        setUser={setUser}
                        user={user}
                        searchInput={searchInput}
                        activeStudents={activeStudents}
                    /> : <LoadingStudentNav />}
                </div>
                <div className={`col d-lg-block ${user ? "" : "d-none"}`}>
                    {students ? <MessageBody
                        user={user}
                        setUser={setUser}
                        draft={draft}
                        setDraft={setDraft}
                        messages={messages}
                        students={students}
                        loading={loading}
                        loading1={loading1}
                        sendMessage={sendMessage}
                        activeStudents={activeStudents}
                    /> : <LoadingBody />}
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Messages






const MessageNavigation = ({ students, setUser, user, searchInput, activeStudents }) => {
    return (
        <div class='py-2 px-2' style={{ maxHeight: '70vh', overflow: 'scroll' }}>
            <nav class="nav" >
                {students
                    .filter(item => {
                        if (searchInput) {
                            if (item.name.toLowerCase().includes(searchInput.toLowerCase())) return true;
                            else return false;
                        } else {
                            return true
                        }
                    })
                    .sort()
                    .map((item, index) => <SideMessagesCard
                        name={item.name}
                        avatar={item.avatar}
                        lastMsg={item.lastMsg}
                        lastMsgTime={item.lastMsgTime}
                        unreadMsg={item.unreadMsg}
                        setUser={setUser}
                        id={item._id}
                        user={user}
                        activeStudents={activeStudents}
                    />)
                }
            </nav>
        </div>
    )
}




const Search = ({ searchInput, setSearchInput }) => {
    return (
        <div className='card bg-white'>
            <div className='card-body py-2'>
                <form>
                    <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fas fa-search"></i>
                            </span>
                        </div>
                        <input
                            className="form-control search"
                            type="search"
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}




const SideMessagesCard = ({ name, lastMsg, unreadMsg, lastMsgTime, avatar, setUser, user, id, activeStudents }) => {
    return (
        <div className="text-reset nav-link p-0 mb-1 w-100" >
            <div class={`card pointer ${!lastMsg && 'bg-light'}`} onClick={() => setUser(id)} style={{ border: user === id ? '0.5px solid rgba(0, 0, 0, 0.3)' : '' }}>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-auto'>
                            <div className='avatar avatar-md'>
                                <img class="avatar-img rounded-circle" src={avatar} alt="" />
                            </div>
                        </div>
                        <div className='col'>
                            <div className="">
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 class={`mb-0 mr-auto h4 ${activeStudents.includes(id) ? '' : 'text-secondary'}`}>{name}</h6>
                                    <p class="small mb-0 text-muted">{moment(lastMsgTime).format('LLL')}</p>
                                </div>
                                {lastMsg ? <div className="text-secondary">{lastMsg}</div> : <div className="text-secondary">Start a chat</div>}
                            </div>
                        </div>
                    </div>
                </div>
                {(!isNaN(unreadMsg) && unreadMsg > 0) && <div className="badge-message">
                    <span>{unreadMsg}</span>
                </div>}
            </div>
        </div>
    )
}





const MessageBody = ({ user, setUser, draft, setDraft, students, messages, sendMessage, loading, loading1, activeStudents }) => {

    const { state } = useContext(Store)
    const student = (user && students.filter(item => item._id === user).length > 0) ? students.filter(item => item._id === user)[0] : 'NOT_SELECTED';
    const chat = user ? (messages[user] ? messages[user] : []) : [];




    // const handleMessageAllowance = async () => {
    //     await axios.get(`/api/instructor/checkAccess/${student && student._id}`).then((res) => {
    //         if (res.status === 200) {
    //             setIsActive(true)
    //             setIsActive(res.data)
    //         }
    //     })
    //     .catch((err) => {
    //         setIsActive(false)
    //     }
    //     )
    // }

    // useEffect(() => {
    //     if(student && student._id){
    //         handleMessageAllowance();
    //     }
    // }, [student._id])

    return (
        <div className={`card border shadow ${student === 'NOT_SELECTED' && 'bg-light'}`}>
            {student !== 'NOT_SELECTED' ? <><div className='card-header py-5'>
                <div className='row align-items-center '>
                    <div className={`col-auto d-lg-none ${user ? "" : "d-none"}`}>
                        <div className='pointer' onClick={() => setUser(null)}>
                            <IoChevronBack />
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className='avatar avatar-md'>
                            <img class="avatar-img rounded-circle" src={student.avatar} alt="" />
                        </div>
                    </div>
                    <div className='col'>
                        <h1 class='mb-0 h3'>{student.name}</h1>
                    </div>
                </div>
            </div>
                {(!activeStudents.includes(user)) ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px", flexDirection: "column" }}>
                        <h3>This Enrollment is not active anymore.</h3>
                        {/* <span>To enter this chat, you must schedule new sessions</span> */}
                    </div>
                    :
                    <div className='card-body py-0 ' style={{ maxWidth: "750px" }}>
                        <div className='pb-0 mb-0' style={{ height: '65vh', overflow: 'scroll', display: 'flex', flexDirection: 'column-reverse' }}>
                            {chat.map(item => <Message message={item.message} time={item.time} from={item.from} loading={loading1 === item._id} />)}
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-2">
                            <div className="d-flex align-items-center">
                                <div className="col-auto">
                                    <div className="avatar avatar-sm">
                                        <img src={state.user.data.avatar} alt="..." class="avatar-img rounded-circle" />
                                    </div>
                                </div>
                                <div className="col ml-n2">
                                    <form class="mt-1">
                                        <input
                                            class="form-control form-control-flush"
                                            value={draft}
                                            onChange={e => setDraft(e.target.value)}
                                            placeholder="Write your message"
                                            style={{ overflow: 'hidden', overflowWrap: 'break-word', height: '40px' }}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    if (!loading) sendMessage(e)
                                                }
                                            }}
                                        />
                                    </form>
                                </div>
                            </div>
                            <div className="col-auto align-self-end">
                                <button className="btn btn-rounded-circle btn-primary lift shadow" disabled={loading} onClick={(e) => sendMessage(e)}>
                                    {loading ? <span class='spinner-border spinner-border-sm'></span> : <IoIosSend class='h2 mb-0' />}
                                </button>
                            </div>
                        </div>
                    </div>}
            </> : <div class="card-body d-flex align-items-center justify-content-center text-uppercase text-muted h2" style={{ height: '90vh' }}>
                Choose a member to initiate the chat
            </div>
            }
        </div >
    )
}



const Message = ({ message, time, from, loading }) => {
    if (from === 'instructor') return <RightMessage message={message} time={time} loading={loading} />
    else return <LeftMessage message={message} time={time} />
}

const LeftMessage = ({ message, time }) => {
    return (
        <div className='row mt-4'>
            <div className='col-10 col-xl-7'>
                <div className='card bg-secondary-soft mb-0'>
                    <div className='card-body'>
                        {FileHelper.isImage(message) ? <Image src={message} width="50%" /> : <div class='preline'>{message}</div>}
                        <div className='text-muted small mt-2'>{moment(time).format('LLL')}</div>
                    </div>
                </div>
            </div>
            <div className='col'></div>
        </div>
    )
}


const RightMessage = ({ message, time, loading }) => {
    return (
        <div className='row mt-4'>
            <div className='col'></div>
            <div className='col-10 col-xl-7'>
                <div className={`card text-white mb-0`} style={{ backgroundColor: loading ? '#ffc198' : '#ff6700' }}>
                    <div className='card-body py-4'>
                        {FileHelper.isImage(message) ? <Image src={message} width="50%" /> : <div class='preline'>{message}</div>}
                        {/* <div class='preline'>{message}</div> */}
                        <div className='text-white small mt-2'>{loading ? <span class="spinner-border spinner-border-sm"></span> : moment(time).format('LLL')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export const getDistinct = (array) => {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if (!map.has(item._id)) {
            map.set(item._id, true);    // set any id to Map
            result.push(item);
        }
    }
    return result
}









const LoadingStudentNav = () => {
    return (
        <div>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
        </div>
    )
}


const LoadingBody = () => {
    return (
        <div>
            <Skeleton height={800} />
        </div>
    )
}






const cleanMessage = (message) => {
    const string = message.split(' ').join('').split('+').join('').split('-').join('').split('/').join('');
    return (consecutiveDigits(string))
}



function consecutiveDigits(str) {
    var curr, prev, count = 0;

    for (var i = 0; i < str.length; ++i) {
        curr = parseInt(str.split('')[i]);


        if (curr > -1) {
            if (count === 0) {
                ++count;
            }
            else if (prev + 1 === i) {
                ++count;
                if (count === 5) {
                    return true;
                }
            }
            prev = i;
        } else {
            count = 0;
        }
    }
    return false;
}

import React, { useState, useEffect, useContext } from 'react'
import DashboardLayout from '../index'
import TableDragSelect from 'react-table-drag-select'
import { Store } from '../../../../store'
import { saveInstructorProfile } from '../../../../store/actions/auth'
import axios from '../../../../utilis/axios'
import instructor_content from '../../../../content/instructor_content.json'
import { Input, Switch } from '@material-ui/core'
import { Button, Modal } from 'react-bootstrap'
import { BiCross, BiLoaderCircle } from 'react-icons/bi'
import { RxCross2 } from "react-icons/rx";
import cogoToast from 'cogo-toast'
import moment from 'moment'
import { IoClose } from 'react-icons/io5'
import "react-datepicker/dist/react-datepicker.css";
import "./availability.css"

// import TimePicker from '@ashwinthomas/react-time-picker-dropdown'
const ReactDatePicker = require('react-datepicker').default;


const Availability = (props) => {

    const { state, dispatch } = useContext(Store)
    const [cells, setCells] = useState(Array(25).fill().map(_ => Array(8).fill(false)));
    const [profile, setProfile] = useState(state.user.data.instructor_profile)
    const [editAvailability, setEditAvailability] = useState(false);
    const [loading, setLoading] = useState(false);
    const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    const [slotDay, setSlotDay] = useState(null);
    const [slotStart, setSlotStart] = useState(null);
    const [slotEnd, setSlotEnd] = useState(null);
    const [showAddOverride, setShowAddOverride] = useState(false);
    const [notAvailable, setNotAvailable] = useState(false);

    const [availability, setAvailability] = useState({
        MON: [],
        TUE: [],
        WED: [],
        THU: [],
        FRI: [],
        SAT: [],
        SUN: []
    });
    const [override, setOverride] = useState([]);


    const addTimeSlot = async () => {
        // console.log('slotDay : ', slotDay, ' & slotSt: ', slotStart, ' & slotEnd : ', slotEnd);
        if (!slotEnd) {
            cogoToast.info('Provide endTime of slot');
            return;
        }
        if (!slotStart) {
            cogoToast.info('Provide startTime of slot');
            return;
        }
        // Convert slotStart and slotEnd to Date objects for comparison
        const startTime = moment(slotStart).format('HH:mm');
        const endTime = moment(slotEnd).format('HH:mm');

        if (startTime >= endTime) {
            cogoToast.info('Start time must be earlier than end time');
            return;
        }
        try {
            setLoading(true);
            const newAvailability = { ...availability };
            newAvailability[slotDay] = [...newAvailability[slotDay], { startTime, endTime }];
            setAvailability(newAvailability);
            const res = await axios.put('/api/instructor/update/profile/availability', newAvailability)
            if (res.status == 200) {
                cogoToast.success('Availabiltiy updated');
            }
            getInstructorAvailability();
            setLoading(false);
            return;
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const removeTimeSlot = async (slotDay, startTime, endTime) => {
        try {
            const updatedDaySlots = availability[slotDay].filter(
                slot => slot.startTime !== startTime || slot.endTime !== endTime
            );

            const newAvailability = {
                ...availability,
                [slotDay]: updatedDaySlots
            };
            const res = await axios.put('/api/instructor/update/profile/availability', newAvailability)
            if (res.status == 200) {
                cogoToast.success('Availabiltiy updated');
            }
            setAvailability(newAvailability);
            getInstructorAvailability();
            return;
        } catch (error) {
            console.log(error);
        }
    };

    const setSlot = (day, value, type) => {
        // console.log('day : ', day, ' & value : ', value, ' & type : ', type, 'slotDaye : ', slotDay);
        if (slotDay && slotDay != day) {
            // console.log('null');
            setSlotDay(null);
            setSlotStart(null);
            setSlotStart(null);
        }
        setSlotDay(day);
        if (type == 'start') {
            // console.log('start');
            setSlotStart(value);
        }
        else if (type == 'end') {
            // console.log('end');
            setSlotEnd(value);
        }
    }


    const getInstructorAvailability = async () => {
        const res = await axios.get('/api/instructor/get/profile/availability');
        if (res.status == 200) {
            console.log(res.data.instructor);
            setAvailability(res.data.instructor.availabilityTimings);
            if (res.data.instructor.override) {
                setOverride(res.data.instructor.override);
            }
        }
    }


    useEffect(() => {
        getInstructorAvailability();
        getInstructorOverride();
    }, []);

    useEffect(() => {

        if (profile.availability && profile.availability.m) {
            const arr = [
                Array(25).fill(false),
                [false, ...profile.availability.m],
                [false, ...profile.availability.tu],
                [false, ...profile.availability.w],
                [false, ...profile.availability.th],
                [false, ...profile.availability.f],
                [false, ...profile.availability.sa],
                [false, ...profile.availability.su],
            ]
            const tranposedCells = arr[0].map((_, colIndex) => arr.map(row => row[colIndex]));
            setCells(tranposedCells)
        }
        //eslint-disable-next-line
    }, [])

    const changeCells = (val) => {
        setCells(val);
        const newProfile = { ...profile }
        const tranposedCells = val[0].map((_, colIndex) => val.map(row => row[colIndex]));
        const newAvailArray = {
            "m": tranposedCells[1].slice(1),
            "tu": tranposedCells[2].slice(1),
            "w": tranposedCells[3].slice(1),
            "th": tranposedCells[4].slice(1),
            "f": tranposedCells[5].slice(1),
            "sa": tranposedCells[6].slice(1),
            "su": tranposedCells[7].slice(1),
        }
        newProfile.availability = newAvailArray
        setProfile(newProfile);
        saveProfile(newProfile)
    }

    const saveProfile = async (newProfile) => {
        try {

            const res = await axios.put(`/api/instructor/profile/availability`, { availability: newProfile.availability })
            newProfile.onboarding.course = true;
            saveInstructorProfile(state.user.data, res.data, dispatch)

        } catch (err) {
            console.log(err)
            throw err;
        }
    }


    const mergeOverlappingOverrideIntervals = (availability, newSlot) => {
        // Add new slot to the availability and sort by start time
        const sortedAvailability = [
            ...availability,
            newSlot
        ].sort((a, b) =>
            moment(a.start).diff(moment(b.start))
        );

        // Merge overlapping intervals
        const mergedAvailability = [];
        let lastInterval = sortedAvailability[0];

        for (let i = 1; i < sortedAvailability.length; i++) {
            const currentStart = moment(sortedAvailability[i].start);
            const currentEnd = moment(sortedAvailability[i].end);

            if (moment(lastInterval.end).isSameOrAfter(currentStart)) {
                // Overlapping intervals, merge them
                lastInterval = {
                    start: moment.min(moment(lastInterval.start), currentStart).toISOString(),
                    end: moment.max(moment(lastInterval.end), currentEnd).toISOString()
                };
            } else {
                // No overlap, push last interval and move to the next
                mergedAvailability.push(lastInterval);
                lastInterval = sortedAvailability[i];
            }
        }
        // Push the final interval
        mergedAvailability.push(lastInterval);

        return mergedAvailability;
    };


    const addOverride = async (date, start, end, notAvailable) => {
        // console.log('date : ', moment(date).format('L'));
        date = moment(date).format('L');
        if (notAvailable == true) {
            let updatedOverrides = [];

            // Use map to check if the date already exists and update accordingly
            updatedOverrides = override.map((override) => {
                if (override.date === date) {
                    // If the date exists, update the availability for that date
                    return {
                        ...override,
                        availability: [],
                        notAvailable: true,
                    };
                }
                return override;
            });


            // If the date does not exist in the overrides, add a new entry
            const dateExists = override.some((override) => override.date === date);
            if (!dateExists) {
                updatedOverrides = [
                    ...updatedOverrides,
                    {
                        date: date,
                        availability: [],
                        notAvailable: true,
                    },
                ];
            }

            await updateOverride(updatedOverrides);
            setOverride(updatedOverrides);
            setShowAddOverride(!showAddOverride);
            return;
        }
        start = moment(start).toISOString()
        end = moment(end).toISOString()

        console.log('date: ', date);
        console.log('start: ', start);
        console.log('end: ', end);
        // return;
        // Initialize a variable for the updated overrides
        let updatedOverrides = [];

        // Use map to check if the date already exists and update accordingly
        updatedOverrides = override.map((override) => {
            if (override.date === date) {
                // If the date exists, update the availability for that date
                const newSlot = { start, end };
                const mergedAvailability = mergeOverlappingOverrideIntervals(override.availability, newSlot);
                return {
                    ...override,
                    availability: mergedAvailability,
                };
            }
            return override;
        });

        // If the date does not exist in the overrides, add a new entry
        const dateExists = override.some((override) => override.date === date);
        if (!dateExists) {
            updatedOverrides = [
                ...updatedOverrides,
                {
                    date: date,
                    availability: [{ start, end }],
                },
            ];
        }

        console.log(updatedOverrides);
        // return;
        // Finally, update the state
        await updateOverride(updatedOverrides);
        setOverride(updatedOverrides);
        setShowAddOverride(!showAddOverride);
        return;
    };

    const removeOverride = async (index) => {
        // Create a variable to store the updated overrides
        let updatedOverrides = [];

        // Filter out the override at the given index and store it in the new variable
        updatedOverrides = override.filter((_, i) => i !== index);

        // Finally, update the state with the updated overrides
        await updateOverride(updatedOverrides);
        setOverride(updatedOverrides);
    };

    const removeOverrideAvailability = async (overrideIndex, availabilityIndex) => {
        // Create a variable to store the updated overrides
        let updatedOverrides = [];

        // Process the data and update the variable
        updatedOverrides = override.map((curr_override, i) => {
            if (i === overrideIndex) {
                // Filter out the availability based on the availabilityIndex
                const updatedAvailability = curr_override.availability.filter((_, j) => j !== availabilityIndex);

                // If availability is empty after filtering, do not include this override
                if (updatedAvailability.length === 0) {
                    return null; // Mark it for removal
                }

                // Return the updated override if availability is not empty
                return {
                    ...curr_override,
                    availability: updatedAvailability,
                };
            }
            return curr_override;
        }).filter((override) => override !== null); // Remove null entries (overrides with empty availability)

        // Finally, update the state with the updated overrides
        await updateOverride(updatedOverrides);
        setOverride(updatedOverrides);
    };


    const updateOverride = async (overrideData) => {
        try {
            const res = await axios.put(`/api/instructor/add/override`, { override: overrideData })
            // newProfile.onboarding.course = true;
            // saveInstructorProfile(state.user.data, res.data, dispatch)
            if (res.status == 200) {
                await getInstructorOverride();
                cogoToast.success('Override updated');
            }
        } catch (err) {
            console.log(err)
            throw err;
        }
    }

    const getInstructorOverride = async () => {
        const res = await axios.get('/api/instructor/get/override');
        if (res.status == 200) {
            console.log(res.data.instructor);
            setOverride(res.data.instructor.override);
        }
    }


    return (
        <DashboardLayout showTitle={false} padding="high" {...props}>
            <div className='d-flex flex-column '>

            <div className='row align-items-center mt-5 border-bottom pb-3 mb-4'>
                <div className='col'>
                    <h3 className='h1'>{instructor_content.availability.title}</h3>
                    <h3 className="p">{instructor_content.availability.sub_heading}</h3>
                </div>
            </div>

            {/* <div className="p mb-2 mt-3  pb-3">{instructor_content.availability.description}</div> */}
            {/* <div className="p mb-2 mt-3 bold">{instructor_content.availability.how_to}</div> */}
            <div className='session-availability-container'>
                <div className='mb-0'>
                    {
                        days.map((item) => (
                            <div>
                            <div className='per-session-availability-container'>
                                
                                <div className='d-flex'>
                                    <div 
                                     className='week-days px-1 justify-content-center d-flex align-items-center text-white '>
                                        <div className='px-2'><p className='fw-bolder'>{item}</p></div>
                                    </div>
                                    <div className='d-flex  flex-column'>
                                        <div className='d-flex  flex-row  align-items-center gap-3 my-3 '>
                                            {<div>
                                                <TimeSelectDropDown day={item} setSlot={setSlot} addTimeSlot={addTimeSlot} />
                                            </div>}
                                            {loading && <div><BiLoaderCircle /></div>}
                                        </div>
                                        <div className='mb-2 d-flex justify-content-center'>
                                            {availability[item].length > 0 ?
                                                availability[item].map((val) => (
                                                    <div className=' my-1 d-  mx-1 align-items-center session-availabilty ' style={{ borderRadius: '14px', backgroundColor: 'orangered', color: 'white' }}>
                                                        {formatTime(val.startTime) + '-' + formatTime(val.endTime)}
                                                        <RxCross2 className='delete-session' style={{ borderRadius: '50%', transform: 'translateY(-1px)', marginLeft: '2px', cursor: 'pointer' }} color='white' onClick={() => { removeTimeSlot(item, val.startTime, val.endTime) }} />
                                                    </div>
                                                )) :
                                                <div style={{ backgroundColor: 'lightgray', borderRadius: '14px' }} className='px-4 py-1 mx-1 d- '>Not available</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <hr />
                            </div>
                        ))
                    }
                </div>
            </div>
            {true &&
                <div className='p-2'>
                    <div className='m-2 '>
                        <div className=' overrides-availability-container w-100 card' style={{ minWidth: '40vw' }}>
                            <h1 className='text-center '>Overrides</h1>
                        <div className=' px-4 w-100' style={{ minWidth: '40vw' }}>
                            <div className='d-flex  flex-row  align-items-center gap-3 mb-3'>
                                <div className='d-flex  flex-row  align-items-center'>
                                    <button className='mx-1' style={{ padding: '5px', border: '1px solid #bbbbbb', backgroundColor: '#f5f5f5', borderRadius: '5px', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => { setShowAddOverride(true) }}>Add Override</button>
                                </div>
                                {/* <div className='d-flex  flex-row  align-items-center'>
                                <button className='mx-1' style={{ padding: '5px', border: '2px solid rgba(252, 92, 0, 1)', backgroundColor: 'rgba(252, 125, 0, 0.3)', color: 'rgba(252, 92, 0, 1)', borderRadius: '5px', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => { setShowAddOverride(true) }}>Edit Override</button>
                            </div>
                            <div className='d-flex  flex-row  align-items-center'>
                                <button className='mx-1' style={{ padding: '5px', border: '2px solid rgba(252, 92, 0, 1)', backgroundColor: 'rgba(252, 125, 0, 0.3)', color: 'rgba(252, 92, 0, 1)', borderRadius: '5px', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => { setShowAddOverride(true) }}>Save Changes</button>
                            </div> */}
                            </div>
                            {override.length == 0 && <div style={{ backgroundColor: 'lightgray', borderRadius: '14px', width: '185px' }} className='px-4 py-1 mx-1 d-'>No override to show</div>}
                            {override.map((item, index) => (
                                <div className='mb-3'>
                                    <div className='d-flex  flex-row  align-items-center justify-content-between '>
                                        <div className='d-flex  flex-row  gap-3  align-items-center justify-content-between '>
                                            <h3>{item.date}</h3>
                                            <h4 className='text-decoration-underline mx-3 pb-2 ' style={{ cursor: 'pointer' }} onClick={() => { removeOverride(index) }}>Remove all override for this date</h4>
                                        </div>
                                    </div>
                                    <div className='d-flex  flex-row  align-content-center  flex-wrap'>
                                        {(item.notAvailable ? item.notAvailable == false : true) && item.availability.map((slot, ind) => (
                                            <div>
                                                <div className=' my-1  px-3 mx-1 align-items-center ' style={{ borderRadius: '14px', backgroundColor: 'orangered', color: 'white' }}>
                                                    {moment(slot.start).format('hh:mm A') + '-' + moment(slot.end).format('hh:mm A')}
                                                    <RxCross2 className='' style={{ borderRadius: '50%', transform: 'translateY(-1px)', marginLeft: '2px', cursor: 'pointer' }} color='white' onClick={() => { removeOverrideAvailability(index, ind) }} />
                                                </div>
                                            </div>
                                        ))}
                                        {(item.notAvailable ? item.notAvailable == true : false) && <div style={{ backgroundColor: 'lightgray', borderRadius: '14px' }} className='px-4 py-1 mx-1 d-inline-block '>You are not available for whole day on this date.</div>}
                                    </div>
                                    <hr />
                                </div>
                            ))}
                        </div>
                        </div>
                    </div>
                    {/* <div className='m-2 '>
                    <div className=' p-4 w-100 card' style={{ minWidth: '40vw' }}>
                        <h1 className='text-center '>Upcoming Override</h1>
                        <div className='d-flex  flex-row  align-items-center justify-content-between gap-3 '>
                            <h3>{moment().format('DD-mm-yyyy')}</h3>
                        </div>
                        <div>
                            <div className=' my-1 d-inline-block px-3 mx-1 align-items-center ' style={{ borderRadius: '14px', backgroundColor: 'orangered', color: 'white' }}>
                                {'1:00pm - 2:00pm'}
                                <RxCross2 className='' style={{ borderRadius: '50%', transform: 'translateY(-1px)', marginLeft: '2px', cursor: 'pointer' }} color='white' />
                            </div>
                            <div style={{ backgroundColor: 'lightgray', borderRadius: '14px' }} className='px-4 py-1 mx-1 d-inline-block '>No override to show</div>
                        </div>
                    </div>
                </div> */}
                </div>
            }
            </div>

            <AddOverrideModal setShowAddOverride={setShowAddOverride} showAddOverride={showAddOverride} addOverride={addOverride} override={override} />
        </DashboardLayout>
    )
}

export default Availability

const TimeSelectDropDown = ({ day, setSlot, addTimeSlot }) => {
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());


    return (
        <div className='d-flex'>

        <div className='mx-2 d-flex flex-column  '>

            <div className='d-flex mb-3  time-set-availability-container' >
            <p className='mx-1 mb-0 noWrap'>Start Time: </p>
            {/* <Input className='mx-1 ' type='time' onChange={(e) => { setSlot(day, e.target.value, 'start') }}></Input> */}
            <ReactDatePicker
                className='form-control mx-1 input-time-availability'
                onChange={(e) => { setStartTime(e); setSlot(day, e, 'start') }}
                showTimeSelectOnly
                showTimeSelect
                dateFormat={'hh:mm a'}
                selected={startTime}
                />
                </div>

        <div className='d-flex time-set-availability-container' >
            <p className='mx-2 mb-0 noWrap'>End Time : </p>
            {/* <Input className='mx-3 ' type='time' onChange={(e) => { setSlot(day, e.target.value, 'end') }}></Input> */}
            <ReactDatePicker
                className='form-control mx-1 input-time-availability '
                onChange={(e) => { setEndTime(e); setSlot(day, e, 'end') }}
                showTimeSelectOnly
                showTimeSelect
                dateFormat={'hh:mm a'}
                selected={endTime}
                minTime={new Date(startTime)}
                maxTime={new Date(new Date().setHours(23, 59, 59))}
                />
         </div>
        </div>
<div className='d-flex flex-column '>
            <Button  className="availability-add-button" onClick={() => { addTimeSlot() }}>Add</Button>
            {/* <Button  className="availability-add-button" onClick={() => { addTimeSlot() }}>Clear</Button> */}

</div>
        </div>
    )
}




const formaTime = (index) => {
    if (index === 12) return index + ' PM'
    return index > 12 ? (index - 12 < 10 ? '0' + (index - 12) : index - 12) + ' PM' : (index < 10 ? '0' + index : index) + ' AM'
}

const formatTime = (time24) => {
    // Split the input time into hours and minutes
    let [hours, minutes] = time24.split(':');
    hours = parseInt(hours);

    // Determine the period (AM/PM)
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12 || 12;

    // Return the formatted time
    return `${hours}:${minutes} ${period}`;
}

const AddOverrideModal = ({ setShowAddOverride, showAddOverride, addOverride, override }) => {
    // console.log('showAddOverride : ', showAddOverride);
    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [notAvailable, setNotAvailable] = useState(false);

    const dateChangeHandler = (date) => {
        // console.log(date);
        // console.log(moment(date).format('L'));
        setStartDate(date);
        date = moment(date).format('L');
        for (const over of override) {
            if (over.date == date) {
                console.log(over);
                over.notAvailable ? setNotAvailable(over.notAvailable) : setNotAvailable(false);
            }
            else{
                setNotAvailable(false);
            }
        }
    }

    const addOverrideHandler = () => {
        if (startDate == null) {
            cogoToast.error('Please select date');
            return;
        }
        if (startTime == null && notAvailable == false) {
            cogoToast.error('Please select start time');
            return;
        }
        if (endTime == null && notAvailable == false) {
            cogoToast.error('Please select end time');
            return;
        }
        addOverride(startDate, startTime, endTime, notAvailable);
    }

    const setEndTimeHandler = (end) => {
        if (startTime == null) {
            cogoToast.error('First choose start time');
            return;
        }
        if (moment(end).isBefore(moment(startTime))) {
            cogoToast.error('End Time must be greater than Start Time');
            return;
        }
        setEndTime(end);
    }

    return (
        <Modal
            centered
            dialogClassName="modal-dialog-centered"
            show={showAddOverride}
        // onHide={() => setShowAddOverride(false)}
        >
            <Modal.Body className="p-4">
                <div className="d-flex justify-content-end">
                    <span onClick={() => setShowAddOverride(false)} className="close-button" style={{ cursor: 'pointer' }}>
                        <IoClose />
                    </span>
                </div>
                <div className="p-4 card d-flex  flex-column  gap-2 ">
                    <h1 className='text-center mb-4'>Add Override</h1>
                    <div className='d-flex  flex-row  align-items-center  justify-content-center '>
                        <p className='pt-0 mb-0'>Select Date : </p>
                        <ReactDatePicker selected={startDate == null ? new Date() : startDate} onChange={(date) => dateChangeHandler(date)} />
                    </div>
                    <div className='d-flex  flex-row  align-items-center justify-content-center  py-3 '>
                        <h3 style={{marginBottom:"0"}}>Not available on this date : </h3>
                        <input type='checkbox' className='pb-3 ' value={notAvailable} checked={notAvailable} onChange={(e) => { setNotAvailable(e.target.checked) }}></input>
                    </div>
                    {/* <div className='d-flex flex-row align-items-center justify-content-between mb-3'>
                        <h3>{moment().format('DD-MM-yyyy')}</h3>
                    </div> */}
                    {!notAvailable && <div className='mx-2 d-flex flex-row  align-items-center  justify-content-center '>
                        {/* <p className='mx-1 mb-0 '>Start Time: </p> */}
                        {/* <Input className='mx-1 ' type='time' onChange={(e) => { setSlot(day, e.target.value, 'start') }}></Input> */}
                        {/* <ReactDatePicker
                            className='form-control mx-1 '
                            onChange={(e) => { setStartTime(e) }}
                            showTimeSelectOnly
                            showTimeSelect
                            dateFormat={'hh:mm a'}
                            selected={startTime == null ? new Date() : startTime}
                        /> */}
                    </div>}
                    {!notAvailable && <div className='mx-2 d-flex flex-row  align-items-center  justify-content-center '>
                        {/* <p className='mx-2 mb-0'>End Time : </p> */}
                        {/* <Input className='mx-3 ' type='time' onChange={(e) => { setSlot(day, e.target.value, 'end') }}></Input> */}
                        {/* <ReactDatePicker
                            className='form-control mx-1 '
                            onChange={(e) => { setEndTimeHandler(e) }}
                            showTimeSelectOnly
                            showTimeSelect
                            dateFormat={'hh:mm a'}
                            selected={endTime == null ? new Date() : endTime}
                        /> */}
                    </div>}
                    <Button className='mx-3 mt-3' size='sm' onClick={addOverrideHandler}>Add</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
import React, { useState, useEffect, useContext } from 'react'
import DashboardLayout from '../index'
import { IoIosSend } from 'react-icons/io'
import useQueryString from '../../../../components/queryString'
import { IoChevronBack } from 'react-icons/io5'
import axios from '../../../../utilis/axios';
import { Store } from '../../../../store'
import moment from 'moment'
import { saveMessages, saveInstructors } from '../../../../store/actions/student';
import Skeleton from 'react-loading-skeleton'
import { getQueryStringValue } from '../../../../components/queryString'
import student_content from '../../../../content/student_content.json'
import { useChannel, useEvent } from '@harelpls/use-pusher'
import cogoToast from 'cogo-toast'
import { Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { containsContinuousNumbers } from '../../../../utilis/helpers'



const Messages = (props) => {




    const { state, dispatch } = useContext(Store);
    const [user, setUser] = useQueryString("user");
    const [instructors, setInstructors] = useState();
    const [messages, setMessages] = useState({});
    const [searchInput, setSearchInput] = useState();
    const [draft, setDraft] = useState();
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const channel = useChannel(state.user.data._id);


    const [isActive, setIsActive] = useState(false);

    useEvent(channel, "private-message", async (data) => {
        editMessage(data.user._id, data.message, moment(), 'instructor', getQueryStringValue('user'), data.messageId)
    });

    useEffect(() => {
        if (!state.student.chat_instructors || state.student.chat_instructors.length === 0) fetchInstructors();
        else {
            if (props.location && props.location.query && props.location.query.instructor) {
                const newInstructors = [...state.student.chat_instructors];
                newInstructors.unshift({
                    _id: props.location.query.instructor._id,
                    name: props.location.query.instructor.firstName + ' ' + props.location.query.instructor.lastName,
                    avatar: props.location.query.instructor.avatar,
                    unreadMsg: 0,
                    type: 'Explore'
                })
                saveInstructors(newInstructors, dispatch)
                setInstructors(newInstructors)
                setMessages({ ...state.student.chat_messages, [props.location.query.instructor._id]: [] })
            } else {
                setInstructors(state.student.chat_instructors)
                setMessages(state.student.chat_messages)
            }
        }
        // eslint-disable-next-line
    }, [])



    useEffect(() => {
        if (state.student.chat_instructors && state.student.chat_instructors.length > 0) {
            setInstructors(state.student.chat_instructors)
        }
    }, [state.student.chat_instructors])

    useEffect(() => {
        if (Object.keys(state.student.chat_messages).length > 0) {
            setMessages(state.student.chat_messages)
        }
        // eslint-disable-next-line
    }, [state.student.chat_messages])


    const fetchInstructors = async () => {
        try {
            const res = await axios.get('/api/student/enrollments');
            const arr = res.data.map(item => ({
                _id: item.instructor._id,
                name: item.instructor.firstName + ' ' + item.instructor.lastName,
                avatar: item.instructor.avatar,
                unreadMsg: 0,
                type: item.type
            }))
            if (props.location && props.location.query && props.location.query.instructor) {
                arr.push({
                    _id: props.location.query.instructor._id,
                    name: props.location.query.instructor.firstName + ' ' + props.location.query.instructor.lastName,
                    avatar: props.location.query.instructor.avatar,
                    unreadMsg: 0,
                    type: 'Explore'
                })
            }

            const unique = getDistinct(arr)
            fetchMessages(unique);
        } catch (err) {
            console.log(err)
        }
    }


    const fetchMessages = async (instructorsTemp) => {
        try {

            const res = await axios.get('/api/student/messages');
            const newInstructors = getDistinct([...instructorsTemp, ...res.data.map(item => ({ _id: item.instructor && item.instructor._id, avatar: item.instructor && item.instructor.avatar, name: item.instructor && item.instructor.firstName + ' ' + item.instructor && item.instructor.lastName, unreadMsg: 0 }))]);
            const temp = [];

            const temp2 = newInstructors.map(item => {

                const allMessages = res.data.filter(item2 => item2.instructor && item2.instructor._id === item._id);
                temp[item._id] = allMessages.map(item3 => ({
                    from: item3.from,
                    message: item3.message,
                    time: item3.sent_date,
                    isRead: item3.isRead,
                    _id: item3._id
                })).sort((a, b) => (new Date(b.time) - new Date(a.time)))

                if (temp[item._id].length === 0) return item
                else return {
                    ...item,
                    lastMsg: temp[item._id][0].message,
                    lastMsgTime: temp[item._id][0].time,
                    unreadMsg: temp[item._id].reduce((a, b) => {
                        if (!b.isRead && b.from === 'instructor') return a + 1
                        else return a
                    }, 0),
                }
            })
            const temp3 = temp2.sort((a, b) => {
                if (a.lastMsgTime) {
                    return new Date(b.lastMsgTime) - new Date(a.lastMsgTime)
                } else return -1
            })
            setInstructors(temp3)
            setMessages(temp)
            saveInstructors(temp3, dispatch)
            saveMessages(temp, dispatch)

        } catch (err) {
            console.log(err);
        }
    }



    const sendMessage = async (e) => {
        e.preventDefault();
        try {
            const new_message_id = new Date() + 1
            setLoading(true);
            setLoading1(new_message_id)

            if (!draft || draft.trim() === '') return;

            if (cleanMessage(draft)) {
                cogoToast.error("Please refrain from sharing your contact details with anyone through the messages. Anyone found attempting the same would not be able to use Dhunguru’s platform in future. ")
                setLoading(false);
                return;
            }


            editMessage(user, draft, moment(), 'student', getQueryStringValue('user'), new_message_id)
            const data = {
                message: draft,
                instructor: user,
            }
            setDraft('');
            await axios.post('/api/student/messages/send', data);
            setLoading(false)
            setLoading1('')

        } catch (err) {
            cogoToast.error("Error occured, we were not able to send your last message. Please refresh and try again");
            setLoading(false)
            setLoading1('')
            console.log(err);
        }
    }

    useEffect(() => {
        if (user && messages[user] && messages[user].filter(item => (!item.isRead && item.from === 'instructor')).length > 0) {
            readMessages()
        }
        // eslint-disable-next-line
    }, [user])

    const readMessages = async (id) => {
        try {
            await axios.post(`/api/student/messages/read/${id ? id : user}`)
            const newMessages = state.student.chat_messages;
            const newInstructors = state.student.chat_instructors
            newMessages[user] = newMessages[user].map(item => {
                if (!item.isRead && item.from === 'instructor') {
                    return { ...item, isRead: true }
                } else return item
            })
            const index = newInstructors.findIndex(item => item._id === user);
            newInstructors[index].unreadMsg = 0;
            saveInstructors(newInstructors, dispatch)
            saveMessages(newMessages, dispatch);

        } catch (err) {
            console.log(err)
        }
    }


    const editMessage = (userId, message, time, from, user, message_id) => {

        const allMessages = Object.values(state.student.chat_messages).flat()
        if (message.length === 0 || allMessages.filter(item => item._id === message_id).length > 0) {
            return;
        }


        const newMessages = { ...state.student.chat_messages };
        let newInstructors = [...state.student.chat_instructors]

        if (!newMessages[userId]) {
            newMessages[userId] = []
        };
        newMessages[userId] = [{
            from: from,
            message: message,
            time: time,
            isRead: user === userId ? true : false,
            _id: message_id
        }, ...newMessages[userId]]

        const index = newInstructors.findIndex(item => item._id === userId);

        if (index < 0) {
            return;
        }

        newInstructors[index].lastMsgTime = new Date();
        newInstructors[index].lastMsg = message.length > 20 ? message.slice(0, 20) + '...' : message;
        newInstructors[index].unreadMsg = newMessages[userId].reduce((a, b) => {
            if (!b.isRead && b.from === 'instructor') return a + 1
            else return a
        }, 0)

        newInstructors = newInstructors.sort((a, b) => {
            if (b.lastMsgTime) {
                return new Date(b.lastMsgTime) - new Date(a.lastMsgTime)
            } else return -1
        })

        saveInstructors(newInstructors, dispatch)
        saveMessages(newMessages, dispatch);
    }


    return (
        <DashboardLayout showTitle={false}>
            <div className='row pt-1 px-lg-6 px-3 mt-3 mt-lg-5' style={{ maxHeight: "87vh" }}>
                <div className={`col-lg-5 col-xl-4 d-lg-block ${user ? "d-none" : ""}`}>

                    <div class='row align-items-center mb-4'>
                        <div className='col h1 mb-0'>
                            Chats
                        </div>
                    </div>

                    <Search searchInput={searchInput} setSearchInput={setSearchInput} />
                    {instructors ? <MessageNavigation
                        instructors={instructors}
                        setUser={setUser}
                        user={user}
                        searchInput={searchInput}
                        readMessages={readMessages}
                    /> : <LoadingInstructorNav />}
                </div>
                <div className={`col d-lg-block ${user ? "" : "d-none"}`}>
                    {instructors ? <MessageBody
                        user={user}
                        setUser={setUser}
                        draft={draft}
                        setDraft={setDraft}
                        messages={messages}
                        instructors={instructors}
                        sendMessage={sendMessage}
                        loading={loading}
                        loading1={loading1}
                        isActive={isActive}
                    /> : <LoadingBody />}
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Messages






const MessageNavigation = ({ instructors, setUser, user, searchInput, readMessages }) => {

    return (
        <div class='py-2 px-2' style={{ maxHeight: '70vh', overflow: 'scroll' }}>
            <nav class="nav" >
                {instructors
                    .filter(item => {
                        if (searchInput) {
                            if (item.name.toLowerCase().includes(searchInput.toLowerCase())) return true;
                            else return false;
                        } else {
                            return true
                        }
                    })
                    .sort()
                    .map((item, index) => <SideMessagesCard
                        name={item.name}
                        avatar={item.avatar}
                        lastMsg={item.lastMsg}
                        lastMsgTime={item.lastMsgTime}
                        unreadMsg={item.unreadMsg}
                        setUser={setUser}
                        id={item._id}
                        user={user}
                        readMessages={readMessages}
                    />)
                }
            </nav>
        </div>
    )
}




const Search = ({ searchInput, setSearchInput }) => {
    return (
        <div className='card bg-white'>
            <div className='card-body py-2'>
                <form>
                    <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fas fa-search"></i>
                            </span>
                        </div>
                        <input
                            className="form-control search"
                            type="search"
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}




const SideMessagesCard = ({ name, lastMsg, unreadMsg, lastMsgTime, avatar, setUser, user, readMessages, id }) => {
    return (
        <div className="text-reset nav-link p-0 mb-1 w-100" >
            <div class={`card pointer ${!lastMsg && 'bg-light'}`} onClick={() => { readMessages(id); setUser(id) }} style={{ border: user === id ? '0.5px solid rgba(0, 0, 0, 0.3)' : '' }}>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-auto'>
                            <div className='avatar avatar-md'>
                                <img class="avatar-img rounded-circle" src={avatar} alt="" />
                            </div>
                        </div>
                        <div className='col'>
                            <div className="">
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 class="mb-0 mr-auto h4">{name}</h6>
                                    {lastMsg && <p class="small mb-0 text-muted">{moment(lastMsgTime).fromNow()}</p>}
                                </div>
                                {lastMsg ? <div className="text-secondary">{lastMsg.length > 20 ? lastMsg.slice(0, 20) + '...' : lastMsg}</div> : <div className="text-secondary">Start your conversation</div>}
                            </div>
                        </div>
                    </div>
                </div>
                {(!isNaN(unreadMsg) && unreadMsg > 0) && <div className="badge-message">
                    <span>{unreadMsg}</span>
                </div>}
            </div>
        </div>
    )
}





const MessageBody = ({ user, setUser, draft, setDraft, instructors, messages, sendMessage, loading, loading1 }) => {

    const { state } = useContext(Store)
    const instructor = (user && instructors.filter(item => item._id === user).length > 0) ? instructors.filter(item => item._id === user)[0] : 'NOT_SELECTED';
    const chat = user ? (messages[user] ? messages[user] : []) : [];

    const [isActive, setIsActive] = useState(true);

    // const handleMessageAllowance = async () => {
    //     await axios.get(`/api/student/checkAccess/${instructor && instructor._id}`).then((res) => {
    //         if (res.status === 200) {
    //             setIsActive(true)
    //         }
    //     })
    //     .catch((err) => {
    //         setIsActive(false)

    //         console.log(err)
    //     }
    //     )

    // }

    // useEffect(() => {
    //     if(instructor && instructor._id){
    //         handleMessageAllowance();
    //     }

    // }, [instructor._id])

    return (
        <>
            {!isActive ? <Card className="mt-5 shadow bg-white rounded">
                <Card.Body>
                    <div>
                        <h3>You're not allowed to access this chat</h3>
                        <span>To access this chat, you have to schedule new Sessions</span>
                    </div>
                    <Button varinat="success" size="sm" className="mt-3">Book New Sessions</Button>
                </Card.Body>
            </Card> :
                <div className={`card border shadow ${instructor === 'NOT_SELECTED' && 'bg-light'}`}>
                    {instructor !== 'NOT_SELECTED' ? <><div className='card-header py-5'>
                        <div className='row align-items-center '>
                            <div className={`col-auto d-lg-none ${user ? "" : "d-none"}`}>
                                <div className='pointer' onClick={() => setUser(null)}>
                                    <IoChevronBack />
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className='avatar avatar-md'>
                                    <img class="avatar-img rounded-circle" src={instructor.avatar} alt="" />
                                </div>
                            </div>
                            <div className='col'>
                                <h1 class='mb-0 h3'>{instructor.name}</h1>
                            </div>
                        </div>
                    </div>

                        {!isActive ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px", flexDirection: "column" }}>
                                <h3>You're not allowed to access this chat</h3>
                                <span>To access this chat, you have to schedule new Sessions</span>
                            </div> :
                            <div className='card-body py-0' style={{ maxWidth: "750px" }}>
                                <div className='pb-0 mb-0' style={{ height: '65vh', overflow: 'scroll', display: 'flex', flexDirection: 'column-reverse' }}>
                                    {chat.map(item => <Message message={item.message} time={item.time} from={item.from} loading={loading1 === item._id} />)}
                                </div>

                                <hr class='m-0' />
                                <div className="row align-items-center py-3 my-2">
                                    <div className="col-auto">
                                        <div className="avatar avatar-sm">
                                            <img src={state.user.data.avatar} alt="..." class="avatar-img rounded-circle" />
                                        </div>
                                    </div>
                                    <div className="col ml-n2">
                                        <form class="mt-1">
                                            <input
                                                class="form-control form-control-flush"
                                                value={draft}
                                                onChange={e => setDraft(e.target.value)}
                                                placeholder="Type your message here"
                                                style={{ overflow: 'hidden', overflowWrap: 'break-word', height: '40px' }}
                                                onKeyDown={e => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        if (!loading) sendMessage(e)
                                                    }
                                                }}
                                            />
                                        </form>
                                    </div>
                                    <div className="col-auto align-self-end">
                                        <button className="btn btn-rounded-circle btn-primary lift shadow" disabled={loading} onClick={(e) => sendMessage(e)}>
                                            {loading ? <span class='spinner-border spinner-border-sm'></span> : <IoIosSend class='h2 mb-0' />}
                                        </button>
                                    </div>
                                </div>
                            </div>}</> : <div class="card-body d-flex align-items-center justify-content-center text-uppercase text-muted h2" style={{ height: '80vh' }}>
                        {student_content.messages}
                    </div>}
                </div>
            }
        </>
    )
}



const Message = ({ message, time, from, loading }) => {
    if (from === 'student') return <RightMessage message={message} time={time} loading={loading} />
    else return <LeftMessage message={message} time={time} />
}

const LeftMessage = ({ message, time }) => {
    return (
        <div className='row mt-4'>
            <div className='col-10 col-xl-7'>
                <div className='card bg-secondary-soft mb-0'>
                    <div className='card-body'>
                        {message}
                        <div className='text-muted small mt-2'>{moment(time).fromNow()}</div>
                    </div>
                </div>
            </div>
            <div className='col'></div>
        </div>
    )
}


const RightMessage = ({ message, time, loading }) => {
    return (
        <div className='row mt-4'>
            <div className='col'></div>
            <div className='col-10 col-xl-7'>
                <div className={`card text-white mb-0`} style={{ backgroundColor: loading ? '#ffc198' : '#ff6700' }}>
                    <div className='card-body py-4'>
                        {message}
                        <div className='text-white small mt-2'>{loading ? <span class="spinner-border spinner-border-sm"></span> : moment(time).fromNow()}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const getDistinct = (array) => {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if (item) {
            if (!map.has(item._id)) {
                map.set(item._id, true);    // set any id to Map
                result.push(item);
            }
        }
    }
    return result
}







const LoadingInstructorNav = () => {
    return (
        <div>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
        </div>
    )
}


const LoadingBody = () => {
    return (
        <div>
            <Skeleton height={800} />
        </div>
    )
}



const cleanMessage = (message) => {
    const string = message.split(' ').join('').split('+').join('').split('-').join('').split('/').join('');
    return (consecutiveDigits(string))
}



function consecutiveDigits(str) {
    var curr, prev, count = 0;

    for (var i = 0; i < str.length; ++i) {
        curr = parseInt(str.split('')[i]);


        if (curr > -1) {
            if (count === 0) {
                ++count;
            }
            else if (prev + 1 === i) {
                ++count;
                if (count === 10) {
                    return true;
                }
            }
            prev = i;
        } else {
            count = 0;
        }
    }
    return false;
}

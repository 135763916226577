import React, { useState, useEffect, useContext } from 'react'
import RightModal from '../../../../../components/Modals/RightSide'
import Select from 'react-select'
import moment from 'moment'
import { Store } from '../../../../../store'
import axios from '../../../../../utilis/axios'
import cogoToast from 'cogo-toast'
import { saveRequests } from '../../../../../store/actions/student'
import { Link } from 'react-router-dom'
import student_content from '../../../../../content/student_content.json'
import { DateFormInput1 } from '../../../../../components/Forms'
import ReactSelect from 'react-select'
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { setHours, setMinutes, setSeconds } from 'date-fns';

const RequestSchedule = ({ show, setShow }) => {

    const { state, dispatch } = useContext(Store);
    const [options, setOptions] = useState(null);
    const [loading, setLoading] = useState(false)
    const [schedule, setSchedule] = useState({ type: '', instructor: '', course: '', date: '', time: null })
    const [enrollments, setEnrollments] = useState()
    const [selectedEnrollment, setSelectedEnrollment] = useState();
    const [errors, setErrors] = useState(Array(4).fill(false));
    const [availability, setAvailability] = useState({});
    const [enrollment_completed, setEnrollment_completed] = useState(false)
    const [selectDate, setSelectedDate] = useState(null);
    const [slotOptions, setSlotOptions] = useState([]);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    useEffect(() => {
        setSchedule({ type: '', instructor: '', course: '', date: '', time: null });
        setEnrollment_completed(false);
        setEnrollments();
        setOptions(null)
        if (show) fetchEnrollments();
    }, [show])


    useEffect(() => {
        setSchedule(prev => ({ ...prev, course: '', date: '', time: null }))
        setEnrollment_completed(false);
    }, [schedule.instructor])


    useEffect(() => {
        setErrors(Array(4).fill(false))
    }, [schedule])

    useEffect(() => {

        setSchedule(prev => ({ ...prev, date: '', time: null }));
        setEnrollment_completed(false);

        if (schedule.course && schedule.course._id) {
            const temp = enrollments.filter(item => (item.course._id === schedule.course._id && item.type === 'paid'))[0]
            setSelectedEnrollment(temp)
            if (temp.classes_completed + temp.classes_requested + temp.classes_scheduled + temp.classes_late_charged >= temp.num_classes || temp.classes_left <= 0) {
                setEnrollment_completed(true);
                cogoToast.info(`You have exhausted the sessions you purchased in this course bundle. Please purchase more to schedule further sessions. You had ${temp.num_classes} sessions out of which ${temp.classes_completed} are completed, ${temp.classes_scheduled} have been scheduled and ${temp.classes_requested} sessions have been requested.`, { position: 'top-left' })
            }
        }
        // eslint-disable-next-line
    }, [schedule.course])


    const validate = () => {
        const newErrors = Array(4).fill(false)

        if (schedule.instructor === '') newErrors[0] = true
        if (schedule.course === '') newErrors[1] = true
        if (schedule.date === '' && !schedule.time.value || schedule.time.value === '') newErrors[2] = true

        setErrors(newErrors);
        if (newErrors.includes(true)) return false
        else return true;
    }


    const fetchEnrollments = async () => {
        try {
            const res = await axios.get('/api/student/enrollments');
            setEnrollments(res.data);
            const temp = res.data.filter(item => item.type === 'paid').filter(item => item.classes_left > 0).map(item => {
                console.log(item, "item daa")
                return {
                    instructor: { value: item.instructor._id, label: item.instructor.firstName + ' ' + item.instructor.lastName },
                    course: { value: item.course, label: item.course.instrument },
                }
            })
            if (temp.length === 0) {
                cogoToast.info("You don't have any paid enrollments.")
            }
            setOptions(temp)

        } catch (err) {
            console.log(err)
        }
    }

    const fetchAvailability = async (date1) => {
        try {

            setAvailability({})
            setSchedule(prev => ({ ...prev, time: null }))
            const res = await axios.post(`/api/student/schedule/instructor_availability/${schedule.instructor}`, { date: date1 })
            if (res.status == 200) {
                const newAvailability = { ...availability };
                newAvailability[date1] = res.data.filter(item => item !== false);
                
                setAvailability(newAvailability)
                console.log(newAvailability[date1])
                if (newAvailability[date1].length === 0) {
                    console.log('abc')
                    setSchedule(prev => ({ ...prev, time: null }))
                }
                else setSchedule(prev => ({ ...prev, time: null }))
                console.log('slots : ', res.data)
                setSlotOptions(newAvailability[date1].map(item => ({ value: item?.start_time , label: moment(item?.start_time).format('hh:mm A') + ' - ' +  moment(item?.end_time).format('hh:mm A')+ ' - ' + Intl.DateTimeFormat().resolvedOptions().timeZone })));
            }
        } catch (err) {
            console.log(err)
        }
    }


    const sendScheduleRequest = async (e) => {
        e.preventDefault()
        try {
            if (validate()) {
                setLoading(true);
                const timeComponents = moment(schedule.time).format('HH:mm:ss');
                const combinedDateTime = `${schedule.date} ${timeComponents}`;
                const scheduleISODate = moment(combinedDateTime, "DD/MM/YYYY HH:mm:ss").toISOString();
                console.log('scheduleISODate : ', scheduleISODate)
                const res = await axios.post('/api/student/schedule/request', { course: schedule.course, schedule: scheduleISODate })

                cogoToast.success(student_content.toasts.request_sent)
                setSchedule({ type: '', instructor: '', course: '', date: '', time: null })
                setShow(false);

                const newRequest = [...state.student.requests];
                newRequest.push(res.data);
                saveRequests(newRequest, dispatch)
                setLoading(false);

                const newEnrollments = [...enrollments]
                const index = newEnrollments.findIndex(item => item.course._id === schedule.course._id);
                newEnrollments[index].classes_requested = newEnrollments[index].classes_requested + 1
                setEnrollments(newEnrollments)
            }
        } catch (err) {
            setLoading(false);
            console.log(err)
        }
    }

    const handleSlotSelect = (e) => {
        console.log(e.target.value);
        if (e.target.value == 'Select a slot') {
            cogoToast.error('Cannot select this option');
        }
        else {
            setSchedule(prev => ({ ...prev, time: e.target.value }))
        }
        return;
    }

    return (
        <RightModal show={show} setShow={setShow}>
            <div className='w-100  mt-1'>
                <div className='' id='details'>
                    <div className='h2'>Request a session</div>
                    <div className='h3 mb-2'>{ }</div>
                </div>

                <form class="mt-3 ">

                    <div class='mb-3 bg-light p-3'>
                        <p class='small mb-1'>{student_content.schedule_view.message_before_schedule}</p>
                        <Link className='small' to={`/my-dashboard/messages`}>Click here to send a message</Link>
                    </div>

                    <div class="form-group mb-5">
                        <label>Select the mentor</label>
                        <Select
                            options={options ? (getDistinct(options.map(item => item.instructor)).length > 0 ? getDistinct(options.map(item => item.instructor)) : [{ value: "", label: "You don't have any paid enrollment" }]) : [{ value: '', label: "Loading..." }]}
                            value={schedule.instructor ? options.map(item => item.instructor).filter(item => item.value === schedule.instructor)[0] : { value: null, label: 'Please select' }}
                            onChange={(val) => setSchedule(prev => ({ ...prev, instructor: val.value }))}
                            isSearchable={false}
                        />
                        {errors[0] && <div class='invalid-feedback d-block'>Please select a guru</div>}
                    </div>

                    <div class="form-group mb-5">
                        <label>Select the membership</label>
                        <Select
                            styles={{ menu: provided => ({ ...provided, zIndex: 99999 }) }}
                            options={options ? getDistinct(options.filter(item => item.instructor.value === schedule.instructor).map(item => item.course)) : [{ value: '', label: "Loading..." }]}
                            value={schedule.course ? options.filter(item => item.instructor.value === schedule.instructor).map(item => item.course).filter(item => item.value === schedule.course)[0] : { value: null, label: 'Please select' }}
                            onChange={(val) => setSchedule(prev => ({ ...prev, course: val.value }))}
                            isDisabled={schedule.instructor ? false : true}
                            isSearchable={false}
                        />
                        {errors[1] && <div class='invalid-feedback d-block'>Please select a membership</div>}
                    </div>

                    <DateFormInput1
                        placeholder="Select a date"
                        label="Date"
                        errorMessage="Please enter a valid date"
                        minimumDate={moment()}
                        setValue={(val) => {
                            console.log(val);
                            setSelectedDate(moment(val).toDate());
                            setSchedule(prev => ({ ...prev, date: val }));
                            fetchAvailability(val)
                        }}
                        value={schedule.date}
                        setError={(val) => setErrors(prev => { prev[3] = val; return prev })}
                        error={errors[2]}
                        isDisabled={schedule.instructor ? false : true}
                    />

                    <div class="form-group my-5">
                        <label>Time Slot</label>
                        <div class='mb-3 bg-light p-3'>
                            <p class='small mb-1'>Session would be scheduled according to your timezone {timeZone}.</p>
                        </div>
                        {/* <Select 
                            value = {schedule.time}
                            options = {availability[schedule.date] ? availability[schedule.date] : [{value: '', label: 'Loading options...'}]}
                            onChange = {e => setSchedule(prev => ({...prev, time: e}))}
                            isDisabled = {(schedule.date && availability[schedule.date]) ? false : true}
                            isSearchable={false} 
                        /> */}
                        {/* {schedule.date &&  availability[schedule.date] &&<DatePicker
                            showTimeSelect
                            disabled={false}
                            showTimeSelectOnly
                            onChange={(e) => {setSchedule(prev => ({...prev, time: e}))}}
                            // onChange={(e) => {console.log(e)}}
                            minTime={setHours(setMinutes(moment(availability[schedule.date][0].start_time).toDate(), moment(availability[schedule.date][0].start_time).toDate().getMinutes()), moment(availability[schedule.date][0].start_time).toDate().getHours())}
                            maxTime={setHours(setMinutes(moment(availability[schedule.date][availability[schedule.date].length-1].start_time).toDate(), moment(availability[schedule.date][availability[schedule.date].length-1].start_time).toDate().getMinutes()), moment(availability[schedule.date][availability[schedule.date].length-1].start_time).toDate().getHours())}
                            // timeIntervals={60}
                            timeCaption="Session Start Time"
                            dateFormat="h:mm aa"
                            selected={schedule.time ?  setHours(setMinutes(new Date(), moment(schedule.time).minutes()), moment(schedule.time).hours()) : setHours(setMinutes(new Date(), 0), 0)}
                        />} 
                        {schedule.date && availability[schedule.date] && <div style={styles.container}>
                            <h1 style={styles.heading}>Select a Time Slot</h1>
                            <select style={styles.select} onChange={(e) => {handleSlotSelect(e)}} defaultValue="Select a slot">
                                <option value="Select a slot">Select a slot</option>
                                {availability[schedule.date].map((slot, index) => {
                                    const startTime = new Date(slot.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                                    const endTime = new Date(slot.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                                    return (
                                        <option
                                            key={index}
                                            value={slot.start_time}
                                        >
                                            {`${startTime}`}
                                        </option>
                                    );
                                })}
                            </select>
                            {schedule.time && (schedule.time != 'Select a slot') && <div style={styles.selectedSlot}>Selected slot : {new Date(schedule.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>}
                        </div>} */}
                        {
                            slotOptions.length > 0 &&
                            <ReactSelect
                                options={slotOptions}
                                onChange={(val) => setSchedule(prev => ({ ...prev, time: val.value }))}
                                isDisabled={schedule.instructor ? false : true}
                                isSearchable={false}
                                placeholder="Select a slot"
                            />
                        }
                        {!schedule?.date && <div class='invalid-feedback d-block'>Not slot available on this date</div>}
                        {errors[3] && <div class='invalid-feedback d-block'>Please choose a slot</div>}
                    </div>
                    {/* <div className="form-group my-5">
                     <label>Date</label>
                    <CalenderInput  setValue = {(val) => {setSchedule((prev) => ({
                      ...prev,
                      date: moment(val).format("Do MMM YY"),
                      time: moment(val).format("hh:mm a"),
                    }));fetchAvailability(val);
                    console.log(
                      new Date(val)
                        .toUTCString()
                        .split(" ")
                        .slice(4, 8)
                        .join(" "),
                      "e"
                    );}} selected={schedule.date} isDisabled={(schedule.instructor ? false : true)}/>
                     {errors[2] && <div class = 'invalid-feedback d-block'>Please select a valid date and time</div>}
                    </div> */}
                    {(schedule.course !== '' && selectedEnrollment) && <div class='card shadow-sm border'>
                        <div class='card-body'>
                            <div class='row'>
                                <div class='col-12'><strong>Overview</strong></div>
                                <div class='col-8'>Sessions purchased:</div>
                                <div class='col-4'>{numberFormat(selectedEnrollment.num_classes)}</div>
                                <div class='col-8'>Sessions completed:</div>
                                <div class='col-4'>{numberFormat(selectedEnrollment.classes_completed)}</div>
                                <div class='col-8'>Sessions remaining:</div>
                                <div class='col-4'>{numberFormat(selectedEnrollment.classes_left)}</div>
                                <div class='col-8'>Sessions scheduled:</div>
                                <div class='col-4'>{numberFormat(selectedEnrollment.classes_scheduled)}</div>
                                <div class='col-8'>Sessions requested:</div>
                                <div class='col-4'>{numberFormat(selectedEnrollment.classes_requested)}</div>
                            </div>
                            {selectedEnrollment.classes_late_charged > 0 && <div class='small mt-3'>Sessions Charged (Due to Late Cancel /Reschedule): {selectedEnrollment.classes_late_charged}</div>}
                            {enrollment_completed ? <div class='invalid-feedback d-block'>Your have reached the limit to schedule sessions for this class. Please buy more to continue. You had {selectedEnrollment.num_classes} classes in which {selectedEnrollment.classes_completed} are completed, {selectedEnrollment.classes_scheduled} have been scheduled and {selectedEnrollment.classes_requested} sessions have been requested.</div> :
                                <div class='text-primary mt-3'>Sessions that can be requested: {findRemainingSessions(selectedEnrollment)}</div>}
                        </div>
                    </div>}


                    <button class="btn btn-primary btn-block mt-3" onClick={(e) => sendScheduleRequest(e)} disabled={loading || enrollment_completed}>
                        {loading && <span class='spinner-border spinner-border-sm mr-2'></span>}
                        Send request
                    </button>

                </form>

            </div>
        </RightModal>
    )
}

export default RequestSchedule






export const getDistinct = (array) => {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if (!map.has(item.value)) {
            map.set(item.value, true);    // set any value to Map
            result.push({
                value: item.value,
                label: item.label
            });
        }
    }
    return result
}




export const getAvailability = (array) => {
    let temp = []
    let start1 = 0
    for (let i = 0; i < array.length; i++) {
        if (array[i]) {
            temp.push({ value: start1, label: timeFormat(start1) + ' - ' + timeFormat(start1, true) + " (IST)" })
            start1++
        } else {
            start1++
        }
    }
    return temp
}



const timeFormat = (time, isSecond) => {
    if (time < 12) {
        return time1(time) + ":" + (isSecond ? "45" : "00") + " AM"
    } else if (time === 12) {
        return time1(time) + ":" + (isSecond ? "45" : "00") + " PM"
    } else if (time > 12) {
        return time1(time - 12) + ":" + (isSecond ? "45" : "00") + " PM"
    }
}

const time1 = (time) => time >= 10 ? time : "0" + time



const numberFormat = (num) => {
    if (num < 10) return '0' + num;
    else return num;
}


const findRemainingSessions = (temp) => {
    return temp.num_classes - (temp.classes_completed + temp.classes_requested + temp.classes_scheduled + temp.classes_late_charged)
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '50px',
        fontFamily: 'Arial, sans-serif',
    },
    heading: {
        marginBottom: '20px',
        color: '#333',
    },
    select: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        marginBottom: '20px',
        width: '250px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflowY: 'auto',        // Enable vertical scroll if needed
        maxHeight: '300px',
    },
    selectedSlot: {
        marginTop: '20px',
        color: '#333',
        fontSize: '18px',
    },
};